<template>
  <div>
    <patientSettings
      v-if="this.$store.getters['user/getUser'].role === 'patient'"
    ></patientSettings>

    <psyhSettings
      v-if="this.$store.getters['user/getUser'].role === 'psychologist'"
    ></psyhSettings>
  </div>
</template>

<script>
const patientSettings = () =>
  import("../../components/dashboard/patientDashboard/patientSettings");

const psyhSettings = () =>
  import("../../components/dashboard/psyhDashboard/Settings");

export default {
  name: "settings",
  components: {
    patientSettings,
    psyhSettings,
  },
};
</script>

<style scoped></style>
